import '../sass/app.scss'

import  '@modules/swiper/swiper-bundle.min.css'
import Autoplay from '@modules/swiper/modules/autoplay.min.mjs'
import Navigation from '@modules/swiper/modules/navigation.min.mjs'
import Pagination from '@modules/swiper/modules/pagination.min.mjs'
import Thumbs from '@modules/swiper/modules/thumbs.min.mjs'
import Effect from '@modules/swiper/modules/effect-fade.min.mjs'
import Swiper from 'swiper'
import Typed from 'typed.js'
import  '@modules/aos/dist/aos.css'
import AOS from 'aos'
import './fslightbox.js'

AOS.init()

Swiper.use([Navigation, Autoplay, Pagination, Thumbs, Effect])

if (document.querySelector('.typed-strings')) {
    const typed = new Typed('.typed', {
        stringsElement: '.typed-strings',
        typeSpeed: 5,
        backDelay: 3000,
        loop: true,
        loopCount: Infinity,
        autoInsertCss: true,
    })
}

const portfolio = new Swiper('.portfolio__wrap', {
    slidesPerView: 1.2,
    spaceBetween: 16,
    autoplay: {
        delay: 2500,
        pauseOnMouseEnter: true,
    },
    loop: true,
    pagination: false,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        768: {
            slidesPerView: 2.5,
        },
    }
})

const targetNumber = 15
const duration = 1000

// Function to animate numbers
function animateNumbers(targetNumber, duration) {
    const startNumber = 0
    const interval = 10
    const steps = duration / interval
    const stepSize = (targetNumber - startNumber) / steps

    let currentNumber = startNumber
    let step = 0

    const updateNumber = () => {
        currentNumber += stepSize
        document.querySelector('.numberAnimation').textContent = Math.round(currentNumber)

        step++
        if (step < steps) {
            requestAnimationFrame(updateNumber)
        } else {
            document.querySelector('.numberAnimation').textContent = targetNumber
        }
    }

    updateNumber()
}

// Intersection Observer
const advantagesBlock = document.querySelector('.advantages')
const numberAnimation = document.querySelector('.numberAnimation')

const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5, // Trigger when 50% of the target is visible
}

const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            // Start the animation when the 'advantages' block is visible
            numberAnimation.style.opacity = 1
            animateNumbers(targetNumber, duration)
            observer.disconnect() // Disconnect the observer after triggering once
        }
    })
}, options)

// Start observing the 'advantages' block
if (advantagesBlock) {
    observer.observe(advantagesBlock)
}

document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.js-tab-trigger').forEach(function (tabTrigger) {
        tabTrigger.addEventListener('click', function () {
            const id = this.getAttribute('data-tab')
            const content = document.querySelector('.js-tab-content[data-tab="' + id + '"]')

            document.querySelectorAll('.js-tab-trigger.active').forEach(active => {active.classList.remove('active')})

            document.querySelectorAll('[data-tab="'+id+'"]').forEach(addActive => {
                addActive.classList.add('active')
            })

            document.querySelectorAll('.js-tab-content.active').forEach(active => {active.classList.remove('active')})
            content.classList.add('active')
        })
    })

    if (document.getElementById('spec_slide1') && document.getElementById('spec_tab1')) {
        document.getElementById('spec_slide1').classList.add('active')
        document.getElementById('spec_tab1').classList.add('active')
    }

    const hash = location.hash.substr(1)

    if (hash) {
        const targetTab = document.getElementById(hash)

        if (targetTab) {
            targetTab.classList.add('active')

            if (hash !== 'tab-1') {
                document.getElementById('tab-1').classList.remove('active')
                document.getElementById('tab-first').classList.remove('active')
                document.getElementById('tabs-' + hash).classList.add('active')
            }
        }
    }
})

// // Initialize Swiper for thumbnail navigation
const thumbSlider = new Swiper('.swiper-container-thumbs', {
    autoplay: {
        delay: 4500, // Autoplay interval in milliseconds
    },
    slidesPerView: 1,
    // initialSlide: 1,
    spaceBetween: 40,
    effect: 'fade', // Use fade effect
    fadeEffect: {
        crossFade: true // Enable cross-fade between slides
    },
    navigation: {
        nextEl: '.swiper-button-next-thumb',
        prevEl: '.swiper-button-prev-thumb',
    },
    on: {
        // init: updateSlideCount,
        slideChange: updateSlideCount,
    },
})

document.addEventListener('DOMContentLoaded', function () {
    initialSlideContent()
})

function updateSlideCount() {
    document.querySelectorAll('.hero__back.top-slide').forEach(topSlide => {topSlide.style.opacity=0})

    const currentSlide = this.activeIndex + 1 // Swiper slide index starts from 0
    const totalSlides = this.slides.length

    const title = document.querySelector('.swiper-slide-active')
    const img = document.querySelector('.swiper-slide-active .thumbnail-image')

    if (!img) {
        return
    }

    const
        // imgSrc = img.getAttribute('src'),
        imgSrc = img.getAttribute('id'),
        imgPosElem = document.querySelector('.thumbnail-image-position'),
        imgPos = imgPosElem.getAttribute('data-pos')

    document.querySelector('.hero-title').innerHTML = title.querySelector('.slide-content-hidden').innerHTML

    // document.querySelector('.hero__back').setAttribute('src', imgSrc)
    setTimeout(() => {
        document.querySelector('.hero__back.top-slide[id="'+imgSrc+'"]').style.opacity=1
    }, 300)
    document.querySelector('.hero__back').setAttribute('style', 'object-position:'+imgPos+'!important')

    // console.log(document.querySelector('.hero-title'))

    document.getElementById('current-slide').textContent = currentSlide
    document.getElementById('total-slides').textContent = totalSlides
}

function initialSlideContent() {
    const title = document.querySelector('.swiper-wrapper .swiper-slide:first-of-type')

    if (!title) {
        return
    }

    const img = document.querySelector('.swiper-slide:first-of-type .thumbnail-image'),
        imgSrc = img.getAttribute('src')

    document.querySelector('.hero-title').innerHTML = title.querySelector('.slide-content-hidden').innerHTML
    // document.querySelector('.hero__back').setAttribute('src', imgSrc)
    document.querySelector('.hero__back.top-slide[src="'+imgSrc+'"]').style.opacity=1
}

document.getElementById('menu-toggle').addEventListener('change', function () {
    if (this.checked) {
        document.body.classList.add('lock-scroll')
    } else {
        document.body.classList.remove('lock-scroll')
    }
})

const menuItems = document.querySelectorAll('#menu-toggle li a')

menuItems.forEach(item => {
    item.addEventListener('click', () => {
        document.getElementById('menu-toggle').click()
    })
})

document.addEventListener('DOMContentLoaded', function () {
    const forms = document.querySelectorAll('form')

    forms.forEach(form => {
        form.addEventListener('submit', function (event) {
            event.preventDefault() // Prevent the default form submission

            let isValid = true

            Array.from(form.elements).forEach(element => {
                if (element.classList.contains('wpcf7-validates-as-required')) {
                    if (element.value === '') {
                        element.style.borderColor = 'red'
                        isValid = false
                    } else {
                        element.style.borderColor = '#6C6C6C'
                    }
                }
            })

            if (!isValid) {
                return false // Prevent form submission if validation fails
            }

            // Submit the form after a delay
            setTimeout(function () {
                form.submit()
                document.querySelector('[data-id="#request"]').classList.add('open')
            }, 1500)
        })
    })
})

history.pushState('', '', window.location.pathname)
document.addEventListener('DOMContentLoaded', function() {
    // Open popup
    const openModalButtons = document.querySelectorAll('.open-modal-btn')
    openModalButtons.forEach(function(button) {
        button.addEventListener('click', function(event) {
            event.preventDefault()
            const popup = button.getAttribute('href')
            document.querySelectorAll('.modal-overlay').forEach(function(overlay) {
                if (overlay.getAttribute('data-id') === popup) {
                    overlay.classList.add('open')
                    document.documentElement.style.overflowY = 'hidden'
                }
            })
        })
    })

    // Close popup
    document.querySelectorAll('.modal-overlay').forEach(function(overlay) {
        overlay.addEventListener('click', function(event) {
            if (event.target.classList.contains('modal-close') || event.target.classList.contains('modal-overlay')) {
                event.preventDefault()
                overlay.classList.remove('open')
                document.documentElement.style.overflowY = 'unset'
            }
        })
    })

    // Close popup when pressing the Esc key
    document.addEventListener('keyup', function(event) {
        if (event.which === 27) {
            document.querySelectorAll('.modal-overlay').forEach(function(overlay) {
                overlay.classList.remove('open')
            })
        }
    })
})

console.log('init')